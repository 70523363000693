<template>
  <div>
    <div class="flex flex-col">
      <div class="w-full bg-action-bar px-2 py-2 clearfix">
        <div class="float-right flex flex-wrap">
          <div>
            <router-link class="btn-green" to="/admin/skapa-larmcentral">
              {{ $t("admin_alarm_center.new_alarm_center") }}
              <BaseIcon icon="plus" class="ml-2" />
            </router-link>
          </div>
        </div>
      </div>
      <div class="w-full mt-6">
        <vue-good-table
          styleClass="vgt-table"
          row-style-class="text-sm"
          :columns="columns"
          :rows="alarm_centers"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: false,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'name'">
              <router-link class="link" :to="'/alarm-center/' + props.row._id">
                {{ props.row.name }}
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'edit'">
              <button class="btn-blue-outline" @click.prevent="showEdit(props.row)">
                <BaseIcon icon="pencil-alt" />
              </button>
            </span>
            <span v-else-if="props.column.field == 'securtrack'">
              <router-link tag="button" class="btn-secondary-outline" :to="'/alarm-center/securtrack/' + props.row._id">
                {{ $t("secur_track.secur_track") }}
                <BaseIcon icon="map-marker-alt" class="ml-1" />
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'securtool'">
              <router-link tag="button" class="btn-secondary-outline" :to="'/alarm-center/securtool/' + props.row._id">
                {{ $t("secur_tool") }}
                <BaseIcon icon="history" class="ml-1" />
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'alarms'">
              <router-link tag="button" class="btn-secondary-outline" :to="'/alarm-center/alarm-via-securcloud/' + props.row._id">
                <BaseIcon icon="angle-double-right" class="ml-1" />
              </router-link>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>

    <AlarmCenterUpdate :alarmCenter="updateInfo" @updateAlarmCenter="getAlarmCenters()" />
  </div>
</template>

<script>
import AlarmCenterUpdate from "@/components/modal/alarm_center_update";

export default {
  title() {
    return this.$t("page_titles.alarm_center");
  },
  components: {
    AlarmCenterUpdate,
  },
  data() {
    return {
      alarm_centers: [],
      updateInfo: null,
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    columns() {
      return [
        { label: this.$t("admin_alarm_center.name"), field: "name", sortable: true },
        { label: this.$t("admin_alarm_center.email"), field: "email", sortable: false },
        { label: this.$t("admin_alarm_center.phone_number"), field: "phone", sortable: false },
        { label: "", field: "edit", sortable: false },
        { label: "", field: "securtrack", sortable: false },
        { label: "", field: "securtool", sortable: false },
        { label: "", field: "alarms", sortable: false },
      ];
    },
  },
  methods: {
    getAlarmCenters() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/alarm-central/`)
        .then((response) => {
          this.alarm_centers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    showEdit(alarmCenter) {
      this.updateInfo = _.cloneDeep(alarmCenter);
      this.$modal.show("update-alarm-center");
    },
  },

  created() {
    this.getAlarmCenters();
    this.setPageTitle(`Admin - Larmcentraler`, "alarm_central");
  },
  watch: {
    "$i18n.locale"() {
      document.title = this.$t("page_titles.alarm_center");
    },
  },
};
</script>
